import React, {useEffect, useState, useCallback} from 'react';
import {GoogleOAuthProvider} from '@react-oauth/google';
import * as Sentry from '@sentry/nextjs';
import {useAtomValue} from 'jotai';
import {v4 as generateUuid} from 'uuid';

import {CommonOAuthLoginBadRequestErrorCase} from '@santa-web/gen/open-api/service';
import {mobileService} from '@app/api/app-bridge/mobile-service';
import tokenManagerAtom from '@app/atoms/core/token-manager';
import config from '@app/config';
import {authorizeKakao, authorizeApple, getLineIDToken} from '@app/features/auth/api';
import {useInvalidUserBottomSheet, useSnsLogin} from '@app/features/auth/hooks';
import {SnsSignupAndSigninInfo} from '@app/features/auth/types';
import {LoginPageView} from '@app/features/auth/views';
import {useTypedRouter} from '@app/hooks/useTypedRouter';
import {
  redirect,
  isB2bState,
  isRedirectionState,
  useQueryState,
  useGoToB2bOfferGroupOrderPage,
} from '@app/misc/query-state';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';
import {SantaResponseError} from '@app/utils/error';

const LoginPageContainer = () => {
  const router = useTypedRouter();
  const tokenManager = useAtomValue(tokenManagerAtom);
  const login = useSnsLogin();
  const queryState = useQueryState();
  const goToB2bOfferGroupOrderPage = useGoToB2bOfferGroupOrderPage();

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const {openBottomSheet} = useInvalidUserBottomSheet();

  // NOTE: email login is occurred in `login/email` page
  const handleSnsLogin = useCallback(
    async (request: SnsSignupAndSigninInfo) => {
      const handleBottomSheetSignup = async () => {
        await router.push({pathname: '/onboarding', query: queryState.toQuery()});
      };

      try {
        setIsLoggingIn(true);
        const token = await login(request);
        tokenManager.setToken(token);
        if (isB2bState(queryState)) {
          await goToB2bOfferGroupOrderPage(queryState);
        } else if (isRedirectionState(queryState)) {
          await redirect(router, queryState);
        } else {
          await router.push('/');
        }
      } catch (error) {
        if (error instanceof SantaResponseError) {
          if (error.santaErrorCode === CommonOAuthLoginBadRequestErrorCase.NOT_EXISTS) {
            openBottomSheet(handleBottomSheetSignup);
            return;
          }
        }
        throw error;
      } finally {
        setIsLoggingIn(false);
      }
    },
    [login, openBottomSheet, queryState, router, tokenManager]
  );

  const handleGoogleLogin = async ({idToken}: {idToken: string}) => {
    try {
      await handleSnsLogin({socialSignupVariant: 'GOOGLE', registrationInfo: {idToken}});
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const handleKakaoLogin = async () => {
    try {
      const {kakaoAccessToken} = await authorizeKakao();
      await handleSnsLogin({
        socialSignupVariant: 'KAKAO',
        registrationInfo: {
          kakaoAccessToken,
        },
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const handleAppleLogin = async () => {
    try {
      const redirectUri = window.location.origin + window.location.pathname;
      const {identityToken, authorizationCode} = await authorizeApple(redirectUri, queryState.toQuery());
      await handleSnsLogin({
        socialSignupVariant: 'APPLE',
        registrationInfo: {
          identityToken,
          authorizationCode,
          redirectUri,
        },
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const handleLineAuthenticate = async () => {
    if (isAppBridgeAvailable()) {
      const {
        authData: {lineIdToken},
      } = await mobileService.requestSocialLogin('LINE');
      await handleSnsLogin({
        socialSignupVariant: 'LINE',
        registrationInfo: {
          lineIdToken,
        },
      });
      return;
    }
    const redirectUri = window.location.origin + window.location.pathname;
    try {
      // Line Authorization
      router.push({
        pathname: config.lineAuthUrl as any,
        query: {
          response_type: 'code',
          client_id: config.lineClientId,
          redirect_uri: redirectUri,
          state: generateUuid(),
          scope: 'openid',
        },
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    const {query} = router;

    const handleLineLogin = async (code: string) => {
      try {
        setIsLoggingIn(true);
        const redirectUri = window.location.origin + window.location.pathname;
        const token = await getLineIDToken(code, redirectUri);
        await handleSnsLogin({
          socialSignupVariant: 'LINE',
          registrationInfo: {
            lineIdToken: token,
          },
        });
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        setIsLoggingIn(false);
      }
    };

    const lineCode = Array.isArray(query['code']) ? query['code'].join('') : query['code'];
    if (lineCode) {
      handleLineLogin(lineCode);
    }
  }, [handleSnsLogin, router]);

  const handleEmailLogin = () => {
    router.push({pathname: '/login/email', query: queryState.toQuery()});
  };

  return (
    <GoogleOAuthProvider clientId={config.googleClientId}>
      <LoginPageView
        onKakaoLogin={handleKakaoLogin}
        onGoogleLogin={handleGoogleLogin}
        onAppleLogin={handleAppleLogin}
        onLineLogin={handleLineAuthenticate}
        onEmailLogin={handleEmailLogin}
        isLoggingIn={isLoggingIn}
      />
    </GoogleOAuthProvider>
  );
};

export default LoginPageContainer;
