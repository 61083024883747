import React from 'react';

import AnonymousOnlyGuard from '@app/components/guards/AnonymousOnlyGuard';
import {LoginPageContainer} from '@app/features/auth';

const LoginPage = () => {
  return (
    <AnonymousOnlyGuard>
      <LoginPageContainer />
    </AnonymousOnlyGuard>
  );
};

export default LoginPage;
